import { useState } from 'react';
import React from 'react';
import './SyncLogin.css';
import apiClient from '../../services/apiClient';
import { useNavigate } from 'react-router-dom';

export default function SyncLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();


  const Auth = async (e) => {
    e.preventDefault();
    try {
      if (username === '') {
        setMsg('Please Enter Username');
        return;
      }
      if (password === '') {
        setMsg('Please Enter Password');
        return;
      }
      const { data } = await apiClient.loginUser({
        username: username,
        password: password
      });
      if (!data?.user) {
        console.log("hi");
        setMsg(data.msg);
        return;
      }
      const { user } = data;
      console.log(user[0]);
      navigate('/sync', { state: { username: user[0].username } });
    } catch (error) {
      console.log(error);
      setMsg("Something went wrong...");
    }
  };
  return (
    <div className="sync-container">
      <form onSubmit={Auth} className="sync-form">
        <p className="has-text-centered">{msg}</p>

        <label htmlFor="username">Username:</label>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <label htmlFor="password">Password:</label>
        <input type="password" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
        <div className="btn-container">
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
};
