import Intro from "../Intro/Intro";
import ShowAlert from "../ShowAlert/ShowAlert";
import "./Welcome.css";

export default function Welcome() {

  return (
    <div className="Welcome">
      <div className="dash">
        <ShowAlert />
        <Intro />
      </div>
    </div>
  );
}
