import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import apiClient from '../../services/apiClient';
import './Register.css';

export default function Register({ user, setUser }) {
  const [name, setName] = useState('');
  const [role, setRole] = useState("Personal");
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = Boolean(user?.email);
    if (isAuthenticated) navigate("/");
  }, []);
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const Register = async (e) => {
    e.preventDefault();
    try {

      if (role === '') {
        setMsg('Please Enter Role');
        return;
      }
      if (username === '') {
        setMsg('Please Enter username');
        return;
      }
      if (name === '') {
        setMsg('Please Enter name');
        return;
      }
      if (email === '') {
        setMsg('Please Enter you email');
        return;
      }
      if (!validateEmail(email)) {
        setMsg('Invalid email');
        return;
      }
      if (password === '') {
        setMsg('Please Enter Password');
        return;
      }
      if (confPassword === '') {
        setMsg('Please Enter Confirmation Password');
        return;
      }
      if (password !== confPassword) {
        setMsg('Passwords do not match');
        return;
      }
      const r = await apiClient.checkUsername({ username: username });
      if (r.error) { setMsg('Username already used'); return; }
      const response = await apiClient.registerUser({
        role: role,
        username: username,
        name: name,
        email: email,
        password: password,
        confPassword: confPassword
      });
      // Convenience of logging in without going to login page
      const { data } = await apiClient.loginUser({
        username: username,
        password: password
      });
      const { user } = data;
      setUser(user[0]);
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <section className="hero is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column">
              <form onSubmit={Register} className="form-box box">
                <p className="has-text-centered">{msg}</p>

                <div className="field mt-5">
                  <p className="has-text-centered label">Register to Turntswag</p>
                </div>
                <div className='field-main-div' >
                  <div className='field-div' >
                    <label className="field-lable">Username</label>
                    <input type="text" className="input" placeholder="Username"
                      value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  
                  <div className='field-div' >
                    <label className="field-lable">Name</label>
                    <input type="text" className="input" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>

                </div>

                <div className='field-main-div' >
                  <div className='field-div' >
                    <label className="field-lable">Email</label>
                    <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>

                  <div className='field-div' >
                    <label className="field-lable">Role</label>
                    <select className="slect" onChange={(e) => setRole(e.target.value)}>
                      <option value="Personal" >Personal</option>
                      <option value="Business">Business</option>
                    </select>
                  </div>
                </div>


                <div className='field-main-div' >
                  <div className='field-div' >
                    <label className="field-lable">Password</label>
                    <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>


                  <div className='field-div' >
                    <label className="field-lable">Confirm Password</label>
                    <input type="password" className="input" placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                  </div>
                </div>




                
                <br></br>
                <div className="field-div">
                  <button className="reg-button button is-success is-fullwidth">Register</button>
                </div>
                <Link to="/login">
                  Already have an account?
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}