import React, { useState } from 'react';
import './style.css';
import { Link, useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';


function ForgotPassword({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const UpdatePassword = async (e) => {
    e.preventDefault();
    try {
      await apiClient.updatePassword({
        email: email,
        password: password,
        confPassword: confPassword
      });
      // Convenience of logging in without going to login page
      const { data } = await apiClient.loginUser({
        email: email,
        password: password
      });
      const { user } = data;
      setUser(user[0]);
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  return (
    <div className="styles-container">
      <form onSubmit={UpdatePassword} className="styles-box">
        <div className="styles-header">
          <p>Forgot your password</p>
        </div>
        <div className="styles-headerNext">
          <p></p>
        </div>
        <div className="styles-headerNext">
          <p className="has-text-centered">{msg}</p>
        </div>
        <div className="input-box">
          <label htmlFor="email">Email address linked to your account:</label>
          <input type="email" className="input-field" id="email" required placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <i className="bx bx-envelope"></i>
        </div>
        <div className="input-box">
          <label htmlFor="password">New password:</label>
          <input type="password" id="password" className="input-field" required placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="input-box">
          <label htmlFor="confirm-password">Confirm password:</label>
          <input type="password" id="confirm-password" className="input-field" required placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
        </div>
        <div className="input-box">
          <input type="submit" className="input-submit" value="RESET PASSWORD" />
        </div>
        <div className="styles-forgot">
          <Link to="/login">
            Back To Login
          </Link>
        </div>
      </form>
      <div className="styles-wrapper"></div>
      <div className="styles-wrapper2"></div>
    </div>
  );
}

export default ForgotPassword;