import axios from "axios";

class ApiClient {
  constructor(remoteHostUrl) {
    this.remoteHostUrl = remoteHostUrl;
    this.token = null;
    this.tokenName = "rate_my_setup_token";
    this.production = false; // Set to true for SSH requests, false for HTTP requests
  }
  setToken(token) {
    this.token = token;
  }
  async request({ endpoint, method = "GET", data = {} }) {
    const url = `${this.remoteHostUrl}/${endpoint}`;

    const headers = {
      "Content-Type": "application/json",
"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
"Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Origin": "http://turntswag.com", // set the allowed origin, replace * with the actual origin if known
      "Access-Control-Allow-Credentials": true, // set to true if credentials are allowed to be sent with the request
    };

    if (this.token) {
      headers["Authorization"] = `Bearer ${this.token}`;
    }

    try {
      const res = await axios({ url, method, data, headers});
      return { data: res.data, error: null };
    } catch (error) {
      console.error({ errorResponse: error.response });
      const message = error?.response?.data?.error?.message;
      return { data: null, error: message || String(error) };
    }
  }
  async logoutUser() {
    return await this.request({
      endpoint: `logout`,
      method: `DELETE`,
    });
  }
  async retrieveCodes(data) {
    return await this.request({
      endpoint: `codes`,
      method: `POST`,
      data: data,
    });
  }
  async retrieveSequence(data) {
    return await this.request({
      endpoint: `sequence`,
      method: `POST`,
      data: data,
    });
  }
  async updatePassword(data) {
    return await this.request({
      endpoint: `password`,
      method: `POST`,
      data: data,
    });
  }
  async updateEmail(data) {
    return await this.request({
      endpoint: `email`,
      method: `POST`,
      data: data,
    });
  }
  async checkUsername(data) {
    return await this.request({
      endpoint: `check`,
      method: `POST`,
      data: data,
    });
  }
  async createWallet(data) {
    return await this.request({
      endpoint: `wallet`,
      method: `POST`,
      data: data,
    });
  }
  async retrieveWallets(data) {
    return await this.request({
      endpoint: `wallets`,
      method: `POST`,
      data: data,
    });
  }
  async updateWallets(data) {
    return await this.request({
      endpoint: `balance`,
      method: `POST`,
      data: data,
    });
  }
  async retrieveAccountDetails(data) {
    return await this.request({
      endpoint: `sync`,
      method: `POST`,
      data: data,
    });
  }
  async registerUser(user) {
    return await this.request({
      endpoint: `users`,
      method: `POST`,
      data: user,
    });
  }
  async loginUser(user) {
    return await this.request({
      endpoint: `login`,
      method: `POST`,
      data: user,
    });
  }
  async getToken() {
    return await this.request({
      endpoint: `token`,
      method: `GET`,
    });
  }
  async testAPI() {
    return await this.request({
      endpoint: `test`,
      method: `GET`,
    });
  }
  async getUserData(user) {
    return await this.request({
      endpoint: `sync`,
      method: `POST`,
      data: user,
    });
  }
  createAxios() {
    return axios.create();
  }
}
// "http://localhost:3001" locally
// "http://api.turntswag.com:3001" prod
export default new ApiClient("http://api.turntswag.com:8000");
