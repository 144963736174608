import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import turnt_logo from "./turntswag-logo1.jpg";
import account_logo from "./account.png";
import './Navbar.css'

export default function Navbar({ user, handleLogout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = Boolean(user?.email);
  
  const buttons = isAuthenticated ? (
    <>
      <button onClick={handleLogout} className="button is-light">
        <img className="account-image" src={account_logo} alt="Log out"></img>
      </button></>
  ) : (
    <>
      <Link to="/register" className="button-30">
        <strong>Sign up</strong>
      </Link>
      <Link to="/login" className="button-30">
      <strong> Log in</strong>
      </Link></>
  );



  return (
    <nav className='navbar-outer-body' >
      <div className='navbar-main-body' >
        <Link to="/" className="navbar-item">
          <img className="logo-image" src={turnt_logo} alt="Main logo" />
          </Link>
        <div className='navbar-links'>
          <Link to="/" className="links-pages">
              Home
            </Link>

            <Link to="/profile" className="links-pages">
              Profile
            </Link>

            <Link to="/contact" className="links-pages">
              Contact Us
            </Link>
            <Link to="/about" className="links-pages">
              About
            </Link>
        </div>

        <div className='button-div' >
          {buttons}
        </div>
      </div>
      </nav>
  );
}