import React, { useEffect } from 'react';
import './SyncAccounts.css';
import apiClient from '../../services/apiClient';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const SyncAccounts = () => {
  const location = useLocation();
  // This will send a response based on what the user wanted to send data
  function redirect() {
    window.location.href = "https://pyrepay.com/dashboard.php";
  }
  async function sendInfo(data) {
    try {
      const response = await axios.post('https://pyrepay.com/dashboard.php', { data }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
  const acceptHandler = async () => {
    const { username } = location.state || {};
    console.log(username);
    const { data } = await apiClient.getUserData({
      username: username
    });
    data.response = 1;
    await sendInfo(data);
    redirect();

  };
  const rejectHandler = async () => {
    const data = {};
    data.response = 0;
    await sendInfo(data);
    redirect();
  };

  return (
    <div className="SyncAccounts-container">
      <h1>Would you like to connect your TurntSwag Account Information?</h1>
      <div className="buttons-container">
        <button onClick={acceptHandler} className="yes-button">Yes</button>
        <button onClick={rejectHandler} className="no-button">No</button>
      </div>
    </div>
  );
};

export default SyncAccounts;