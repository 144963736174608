import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Register from "../Register/Register";
import Login from "../Login/Login";
import Welcome from "../Welcome/Welcome";
import Profile from "../Profile/Profile";
import Contact from "../Contact/Contact";
import About from "../About/About";
import { useState } from "react";
import Dashboard from '../Dashboard/Dashboard';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import apiClient from '../../services/apiClient';
import SyncAccounts from '../SyncAccounts/SyncAccounts';
import SyncLogin from '../SyncLogin/SyncLogin';
import Test from '../Test/Test';


export default function App() {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);


  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');


  const handleLogout = async () => {
    try {
      await apiClient.logoutUser();
      setName("");
      setToken("");
      setExpire("");
      setUser({});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar user={user} handleLogout={handleLogout} />
        <Routes>
          <Route path="/forgotpassword" element={<ForgotPassword setUser={setUser} />} />
          <Route path="/login" element={<Login user={user} setUser={setUser} />} />
          <Route path="/register" element={<Register user={user} setUser={setUser} />} />
          <Route path="/" element={<Welcome />}></Route>
          <Route path="/dashboard" element={<Dashboard user={user} name={name} expire={expire} setToken={setToken} setName={setName} setExpire={setExpire} token={token} setUser={setUser} />}></Route>
          <Route path="/profile" element={<Profile user={user} />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/sync" element={<SyncAccounts />}></Route>
          <Route path="/synclogin" element={<SyncLogin />}></Route>
          <Route path="/test" element={<Test />}></Route>
        </Routes>

      </BrowserRouter>

    </div>
  );
}

