import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { useEffect, useState, useContext } from "react";

import {
  ChakraProvider,
  Container,
  Text,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";
import apiClient from "../../services/apiClient";
import { AlertContext } from "../../AlertContext";
import ShowAlert from "../ShowAlert/ShowAlert";

export default function Profile({ user }) {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const { showAlert } = useContext(AlertContext);
  const [wallets, setWallets] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");

  useEffect(() => {
    const isAuthenticated = Boolean(user?.email);
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [navigate, user]);

  const downloadFile = (textArray, filename) => {
    // Emulates a click to download a file in React
    const element = document.createElement("a");
    const file = new Blob([textArray.join(" ")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };
  const UpdatePassword = async (e) => {
    e.preventDefault();
    try {
      await apiClient.updatePassword({
        username: user.username,
        password: password,
        confPassword: confPassword,
      });
      navigate("/");
      showAlert("info", "Password updated");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  const UpdateEmail = async (e) => {
    e.preventDefault();
    try {
      await apiClient.updateEmail({
        oldEmail: user.email,
        email: userEmail,
        username: user.username
      });
      // Update the user email as the user is unaware of this new change
      // Could also be a call to getuser and set the new user to that user
      user.email = userEmail;
      navigate("/");
      showAlert("info", "Email updated");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    const retrieveWallets = async () => {
      const response = await apiClient.retrieveWallets({
        username: user.username,
      });
      if (response.data) { setWallets(response.data); }
    };

    retrieveWallets();
  }, [user]);

  const Wallet = ({ wallet, index }) => {
    const [phrase, setPhrase] = useState("");
    const [sequence, setSequence] = useState("");

    useEffect(() => {
      const retrievePhraseAndSequence = async () => {
        const codes = await apiClient.retrieveCodes({
          username: user.username,
          walletId: wallet.id,
        });
        setPhrase(codes.data[0]);
        const sequence = await apiClient.retrieveSequence({
          username: user.username,
          walletId: wallet.id,
        });
        setSequence(sequence.data[0]);
        // setPhrase(response.phrase);
        // setSequence(response.sequence);
      };

      retrievePhraseAndSequence();
    }, [wallet]);

    return (
     
      <Container>
         <Container className="wallet-number-div" >
          <p className="p-tag-small">Wallet #{index + 1}</p>
          <p className="p-tag-small">Balance: ${wallet.balance}</p>
        </Container>
        
        

        <Container>

          <p className="p-tag-small">Secret Phrase:</p>
          <div className="wallet-number-div-2" >
            <p className="long-p-tag">{" "}
            {[
              phrase?.word1,
              phrase?.word2,
              phrase?.word3,
              phrase?.word4,
              phrase?.word5,
              phrase?.word6,
              phrase?.word7,
              phrase?.word8,
              phrase?.word9,
              phrase?.word10,
              phrase?.word11,
              phrase?.word12,
            ].join(" ")}</p>

              <Button
                onClick={() => {
                  // Get the phrase and send the secret phrase as an array
                  downloadFile(
                    [
                      phrase?.word1,
                      phrase?.word2,
                      phrase?.word3,
                      phrase?.word4,
                      phrase?.word5,
                      phrase?.word6,
                      phrase?.word7,
                      phrase?.word8,
                      phrase?.word9,
                      phrase?.word10,
                      phrase?.word11,
                      phrase?.word12,
                    ],
                    "phrase.txt"
                  );
                }}
                variant="solid"
                size="md"
                className="reg-button button is-success"
              >

                Download secret phrase
              </Button>

          </div>
        </Container>


        <Container>
        <p className="p-tag-small">Recovery Password:</p>
        <p className="long-p-tag">{sequence?.recovery_code}</p>
        </Container>
        <Container>
          {
            <Button
            onClick={() => {
              downloadFile([sequence?.recovery_code], "password.txt");
            }}
              variant="solid"
              size="md"
              className="reg-button button is-success"
            >
              Download recovery password
            </Button>
          }
        </Container>
      </Container>
    );
  };
  const createWallet = async () => {
    const response = await apiClient.createWallet({ username: user.username });
    if (response.data) {
      const newWallet = response.data;

      setWallets([...wallets, newWallet]);
    }
  };

    return (
      <div className="outer-main-div" >
      <div className="inner-main-div" >

          <div className="inner-child-div" >
              <p>Tied to: {user.email}</p>

              <div className="first-inner-child-left-special" >
                  <p className="p-tag">Name: {user.name}</p>
                  <p className="p-tag">Username: {user.username}</p>
              </div>

              <br></br>
              <div className="first-inner-child-left" >
                  <p className="p-tag">Update Email</p>
                  <FormControl className="form-div">
                      <Input
                        className="input-box"
                        id="email"
                        placeholder="Enter new email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                      <Button
                        className="reg-button button is-success "
                        variant="solid"
                        size="md"
                        onClick={UpdateEmail}
                      >
                        Save
                      </Button>
                    </FormControl>
              </div>
              <br></br>
              <div className="first-inner-child-left" >
                  <p className="p-tag">Update Password</p>
                  <FormControl>
                      <div className="form-div">
                        <Input
                          id="password"
                          className="input-box"
                          type="password"
                          placeholder="New Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <Input
                          id="confirm-password"
                          type="password"
                          className="input-box"
                          placeholder="Confirm New Password"
                          value={confPassword}
                          onChange={(e) => setConfPassword(e.target.value)}
                        />
                      </div>
                      <br></br>
                      <Button
                        onClick={UpdatePassword}
                        variant="solid"
                        size="md"
                        className="reg-button button is-success "
                      >
                        Change Password
                      </Button>
                    </FormControl>
              </div>

          </div>
          <div className="inner-child-div-scroll style-1" >
            <Container className="create-button-div" >
              <Button className="reg-button button is-success " variant="solid" size="md" onClick={createWallet}>
                Create Wallet
              </Button>
            </Container>

            <Container>
            <p className="p-tag">Wallets</p>
              <Container>
                {wallets.length === 0 && (
                  <Container>
                    <p className="p-tag-small">No Wallets Yet</p>
                  </Container>
                )}
                {wallets.length !== 0 &&
                  wallets.map((wallet, index) => (
                    <Wallet key={wallet.id} wallet={wallet} index={index} />
                  ))}
              </Container>
            </Container>
          

          </div>

      </div>

  </div>
  );
  
}



// return (
//   <div className="Profile">
//     <ChakraProvider resetCSS>
//       <ShowAlert />
//       <Container>
//         <Text>Name: {user.name}</Text>
//         <Text>Email: {user.email}</Text>

//         <FormControl>
//           <Input
//             id="email"
//             placeholder="email"
//             value={userEmail}
//             onChange={(e) => setUserEmail(e.target.value)}
//           />
//           <Button variant="solid" size="md" onClick={UpdateEmail}>
//             Save
//           </Button>
//         </FormControl>
//         <FormControl>
//           <Input
//             id="password"
//             type="password"
//             placeholder="new password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <Input
//             id="confirm-password"
//             type="password"
//             placeholder="confirm new password"
//             value={confPassword}
//             onChange={(e) => setConfPassword(e.target.value)}
//           />
//           <Button onClick={UpdatePassword} variant="solid" size="md">
//             Change Password
//           </Button>
//         </FormControl>
//         <Container>
//           <Button variant="solid" size="md" onClick={createWallet}>
//             Create Wallet
//           </Button>
//         </Container>
//         <Container>
//           <Text>Wallets</Text>
//           <Container>
//             {wallets.length === 0 && (
//               
//             )}
//             {wallets.length !== 0 &&
//               wallets.map((wallet, index) => (
//                 <Wallet key={wallet.id} wallet={wallet} index={index} />
//               ))}
//           </Container>
//         </Container>
//       </Container>
//     </ChakraProvider>
//   </div>
// );