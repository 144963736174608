import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import apiClient from "../../services/apiClient";

export default function Dashboard({ user, name, expire, setToken, setName, setExpire, token, setUser }) {
    useEffect(() => {
        refreshToken();
    }, []);
    const axiosJWT = apiClient.createAxios();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await apiClient.getToken();
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const refreshToken = async () => {
        try {
            const response = await apiClient.getToken();
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser(decoded);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    };

    const isAuthenticated = Boolean(user?.email);
    const header = isAuthenticated ? (
        <>
            <h2>Welcome Back</h2>
            <h1 className="h1-tag" >{name}</h1>
        </>
    ) : (
        <>
            <Link to="/login" className="button is-light">
                Sign in
            </Link></>
    );
    return (
        <div className="intro" >
            {header}
        </div>
    );
}