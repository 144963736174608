import React, { useEffect, useState } from "react";
import apiClient from "../../services/apiClient";

export default function Test() {
    const [test, setTest] = useState('Nothing');

    useEffect(() => {
        testAPI();
    }, []);
    const testAPI = async () => {
        try {
            const response = await apiClient.testAPI();

            setTest(response.data.message);
        } catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    };

    return (
        <div className="Test">
            {test}
        </div>
    );
}