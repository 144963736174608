import React from 'react';
import './Contact.css';

export default function Contact() {
  const [formStatus, setFormStatus] = React.useState('Send');
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Sent');
    const { name, email, mobile, message } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      mobile: mobile.value,
      message: message.value,
    };
    console.log(conFom);
    const mailToLink = `mailto:turntswag@willlouden.com?subject=${conFom.name}&body=Name: ${conFom.name}%0AEmail: ${conFom.email}%0AMobile: ${conFom.mobile}%0AMessage: ${conFom.message}`;
    window.location.href = mailToLink;
  };
  return (

<section className="hero is-fullheight is-fullwidth">
<div className="hero-body">
  <div className="container">
    <div className="columns is-centered">
      <div className="column">
        <form onSubmit={onSubmit} className="form-box box">
          <div className='field-main-div' >
            <div className='field-div' >
              <label className="field-lable">Name</label>
              <input className="form-control" type="text" id="name" required />
            </div>

            <div className='field-div' >
              <label className="field-lable">Email</label>
              <input className="form-control" type="email" id="email" required />
            </div>
          </div>
          <div className='field-main-div' >

          <div className='field-div' >
              <label className="field-lable">Mobile</label>
              <input className="form-control" type="text" id="mobile" required />
            </div>
            </div>
          <div className='field-main-div' >

              <div className='field-div' >
                  <label className="field-lable">Message</label>
                  <textarea className="form-control" id="message" required />
              </div>
          </div>

          
          <br></br>
          <div className="field-div">
            <button className="reg-button button is-success is-fullwidth">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</section>
  );
}


