import "./About.css";
import Intro from "../Intro/Intro";


import {
  ChakraProvider,
  Container,
  Text,
  Image
} from '@chakra-ui/react';

export default function About() {
  return (

    <div className="intro">
      <div  className="inner-div"  >
        <h2>If you have any feedback, require assistance or have a business and need more of a custom setup for your organization please email us at</h2>
        <h1 className="h1-tag" >Turntswag@willlouden.com</h1>
      </div>
    </div>

    
  );
}


