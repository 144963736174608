import "./Intro.css";

export default function Intro() {
  return (
    <div className="intro">
      <div  className="inner-div"  >
        <h1 className="h1-tag" >Welcome to Turntswag </h1>
        <h2>the wallet  for all your payment processing needs. </h2>
      </div>
      <div>
        <h2>From crypto software wallets to wallets for debit cards.</h2>
        <h2> Control your finances by allowing us to organize your payments.  For full functionality connect your wallets to Pyrepay.com. </h2>
      </div>
    </div>
  );
}


