import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import apiClient from '../../services/apiClient';
import './Login.css';

export default function Login({ user, setUser }) {
    const navigate = useNavigate();
    useEffect(() => {
        const isAuthenticated = Boolean(user?.email);
        if (isAuthenticated) navigate("/");
    }, []);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');

    const Auth = async (e) => {
        e.preventDefault();
        try {
            if (username === '') {
                setMsg('Please Enter Username');
                return;
            }
            if (password === '') {
                setMsg('Please Enter Password');
                return;
            }
            const { data } = await apiClient.loginUser({
                username: username,
                password: password
            });
            const { user } = data;
            setUser(user[0]);
            navigate("/dashboard");
        } catch (error) {
            setMsg("Something went wrong...");
        }
    };

    return (
        <section className="hero is-fullheight is-fullwidth">
             <div className="hero-body">
                 <div className="container">
                     <div className="columns is-centered">
                        <div className="column">
                            <form onSubmit={Auth} className="form-box box">
                                <p className="has-text-centered error-message">{msg}</p>
                                
                                <div className="field mt-5">
                                    <p className="has-text-centered label">Log in to Turntswag</p>
                                    <label className="label">Username</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="reg-button button is-success is-fullwidth">Login</button>
                                </div>
                                <Link to="/register">
                                    Create an account?
                                </Link>
                                <br></br>
                                <Link to="/forgotpassword">
                                    Forgot password?
                                </Link>
                            </form>
                        </div>
                     </div>
                 </div>
             </div>
         </section>
    );
}
