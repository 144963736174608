import React, { useContext } from "react";
import { AlertContext } from "../../AlertContext";


const ShowAlert = () => {
  const { alert, hideAlert } = useContext(AlertContext);

  if (!alert) {
    return null;
  }

  return (
    <div className={`alert alert-${alert.type}`} role="alert">
      {alert.message}
      <button type="button" className="close" onClick={hideAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default ShowAlert;
